/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react"
import { optionsChannels } from "@components/pageHablemos/components/util"

const ViewTwo = ({
  numberWorkers,
  setSectionView,
  setSectionViewTwo,
  setDataTwo,
  dataTwo
}) => {

  const [selectedOptionsChannel, setSelectedOptionsChannel] = useState(dataTwo.channels ? dataTwo.channels : [])

  const handleOptionChannelChange = option => {
    const isSelected = selectedOptionsChannel.includes(option.id)

    if (isSelected) {
      // Si ya está seleccionado, quitarlo del array
      setSelectedOptionsChannel(prevState =>
        prevState.filter(id => id !== option.id)
      )
    } else {
      console.log("sss")
      // Si no está seleccionado, agregarlo al array
      setSelectedOptionsChannel(prevState => [...prevState, option.id])
    }
  }


  //Estado valores inputs
  const [values, setValues] = useState({
    channels: selectedOptionsChannel,
    workers: dataTwo.workers ? dataTwo.workers : "default",
    description: dataTwo.description ? dataTwo.description : "",
  })

  useEffect(() => {
    setValues({ ...values, channels: selectedOptionsChannel })
  }, [selectedOptionsChannel])

  useEffect(() => {
    setDataTwo(values)
  }, [values])

  const {
    channels,
    description,
    workers,
  } = values

  //Estado validaciones
  const [validations, setValidations] = useState({
    channels: "",
    workers: "",
    description: "",
  })

  //Validar todos los campos que no esten vacios y en el formato correcto al presionar enviar
  const validateAll = () => {
    const {
      channels,
      workers,
      description,
    } = values

    const validations = {
      channels: "",
      workers: "",
      description: "",
    }
    let isValid = true
    if (!channels) {
      validations.channels = "Campo requerido"
      isValid = false
    }
    if (workers === "default") {
      validations.workers = "Campo requerido"
      isValid = false
    }
    if (!description) {
      validations.description = "Campo requerido"
      isValid = false
    }
    if (!isValid) {
      setValidations(validations)
    }

    return isValid
  }

  //Validar campos mientras el usuario escribe
  const validateOne = e => {
    const { name } = e.target
    const value = values[name]
    let message = ""

    if (!value) {
      message = `Campo requerido`
    }
    setValidations({ ...validations, [name]: message })
  }

  //Obtener y guardar valores de los inputs
  const handleChange = e => {
    const { name, value } = e.target
    setValues({ ...values, [name]: value })
  }

  const {
    channels: channelsVal,
    description: descriptionVal,
    workers: workersVal,
  } = validations

  //Funcion envio formulario
  const handleSubmitTwo = async () => {
    const isValid = validateAll()

    //Validar los campos
    if (isValid) {
      setSectionViewTwo(false)
      document.body.scrollTop = 0 // For Safari
      document.documentElement.scrollTop = 0 // For Chrome, Firefox, IE and Opera
    } else {
      return false
    }
  }

  return (
    <div className={"container-page-hablemos-form"}>
      <hr className="container-page-hablemos-form-blueTwo" />
      <div className="container-page-hablemos-form-form">
        <div className="container-page-hablemos-form-title">
          <p className="container-page-hablemos-form-title_title">
            Cuéntanos sobre tu necesidad
          </p>
          <div className="container-page-hablemos-form-title_view" >
          Paso 2/3
          </div>
        </div>
        <div className="container-page-hablemos-form-container_checkbox">
          <p className="container-page-hablemos-form-inputContainer_label">
            ¿Cuáles canales tienes planeado usar?
          </p>
          <div className="container-page-hablemos-form-container_checkbox-channels">
            {optionsChannels.map(option => (
              <div
                key={option.id}
                className={`container-page-hablemos-form-container_checkbox-channels-card  ${selectedOptionsChannel.includes(option.id) ? "selected" : ""
                  }`}
              >
                <input
                  type="checkbox"
                  value={channels}
                  id={`channel${option.id}`}
                  checked={selectedOptionsChannel.includes(option.id)}
                  onChange={() => handleOptionChannelChange(option)}
                />
                <label htmlFor={`channel${option.id}`}>
                  <div className="container-page-hablemos-form-container_checkbox-channels-card-info">
                    <img src={option.image} alt={option.title} />
                    <div>
                      <p>{option.description}</p>
                    </div>
                  </div>
                </label>
              </div>
            ))}
          </div>
          <br />
          <div className="error">{channelsVal}</div>
        </div>

        <div className="container-page-hablemos-form-inputContainer">
          <div style={{ position: "relative" }}>
            <p className="container-page-hablemos-form-inputContainer_label">
              ¿Cuántos agentes usarán la plataforma?
            </p>
            <select
              className={
                workers === "default"
                  ? "  container-page-hablemos-form-inputContainer_inputOne select-default "
                  : "container-page-hablemos-form-inputContainer_inputOne"
              }
              type="text"
              name="workers"
              value={workers}
              onChange={handleChange}
              onBlur={validateOne}
              defaultValue={workers}
            >
              <option value="default" disabled hidden>
                Seleccionar
              </option>
              {numberWorkers.map((element, index) => {
                return (
                  <option key={index} value={element}>
                    {element}
                  </option>
                )
              })}
            </select>
            <i></i>
            <div className="error">{workersVal}</div>
          </div>
        </div>
        <div className="container-page-hablemos-form-textareaContainer">
          <div>
            <p className="container-page-hablemos-form-textareaContainer_label">
              Por último, cuéntanos, ¿cómo deseas que te ayuden nuestras
              soluciones?
            </p>
            <textarea
              className="container-page-hablemos-form-textareaContainer_input"
              placeholder="Escribir aquí...."
              type="text"
              name="description"
              value={description}
              onChange={handleChange}
              onBlur={validateOne}
              defaultValue={description}
            />
            <div className="error">{descriptionVal}</div>
          </div>
        </div>
        <div
          style={{
            marginTop: "31px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <button
            className="container-page-hablemos-form-btnSecondary"
            onClick={() => {
              setSectionView(true)
              document.body.scrollTop = 0 // For Safari
              document.documentElement.scrollTop = 0 // For Chrome, Firefox, IE and Opera
            }}
          >
            <svg
              width="25"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.0303 18.5303C11.7374 18.8232 11.2626 18.8232 10.9697 18.5303L4.96967 12.5303C4.67678 12.2374 4.67678 11.7626 4.96967 11.4697L10.9697 5.46967C11.2626 5.17678 11.7374 5.17678 12.0303 5.46967C12.3232 5.76256 12.3232 6.23744 12.0303 6.53033L7.31066 11.25L19.5 11.25C19.9142 11.25 20.25 11.5858 20.25 12C20.25 12.4142 19.9142 12.75 19.5 12.75L7.31066 12.75L12.0303 17.4697C12.3232 17.7626 12.3232 18.2374 12.0303 18.5303Z"
                fill="#262A31"
              />
            </svg>{" "}
            Volver
          </button>
          <button
            className="container-page-hablemos-form-btnPrimary"
            onClick={e => handleSubmitTwo(e)}
          >
            Continuar{" "}
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.4697 5.46967C12.7626 5.17678 13.2374 5.17678 13.5303 5.46967L19.5303 11.4697C19.8232 11.7626 19.8232 12.2374 19.5303 12.5303L13.5303 18.5303C13.2374 18.8232 12.7626 18.8232 12.4697 18.5303C12.1768 18.2374 12.1768 17.7626 12.4697 17.4697L17.1893 12.75H5C4.58579 12.75 4.25 12.4142 4.25 12C4.25 11.5858 4.58579 11.25 5 11.25H17.1893L12.4697 6.53033C12.1768 6.23744 12.1768 5.76256 12.4697 5.46967Z"
                fill="#262A31"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  )
}

export default ViewTwo
