import React, { useEffect, useState } from "react"
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import { optionsRedes } from "@components/pageHablemos/components/util"
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from "react-loader-spinner"

const ViewThree = ({
  onClickConfirm,
  dataOne,
  dataTwo,
  setSectionViewTwo,
  dataThree,
  setDataThree
}) => {
  //Estado valores inputs
  const [values, setValues] = useState({
    products: dataThree.products ? dataThree.products : "",
  })

  //Estado validaciones
  const [validations, setValidations] = useState({
    products: "",
  })

  //Validar todos los campos que no esten vacios y en el formato correcto al presionar enviar
  const validateAll = () => {
    const {
      products,
    } = values

    const validations = {
      products: "",
    }
    let isValid = true
    if (products === "") {
      validations.products = "Campo requerido"
      isValid = false
    }

    if (!isValid) {
      setValidations(validations)
    }

    return isValid
  }

  //Validar campos mientras el usuario escribe
  const validateOne = e => {
    const { name } = e.target
    const value = values[name]
    let message = ""

    if (!value) {
      message = `Campo requerido`
    }

    setValidations({ ...validations, [name]: message })
  }

  //Obtener y guardar valores de los inputs
  const handleChange = e => {
    const { name, value } = e.target
    setValues({ ...values, [name]: value })
  }

  const {
    products,
  } = values

  const {
    products: productsVal,
  } = validations


  const [selectedOptionProduct, setSelectedOptionProduct] = useState(
    dataThree.products ? dataThree.products : ""
  )

  const handleOptionProductChange = option => {
    setSelectedOptionProduct(option.id)
    setValues({ ...values, products: option.id })
  }

  useEffect(() => {
    setDataThree(values)
  }, [values])

  //Estado para mostrar el spin de cargando en boton enviar
  const [spin, setSpin] = useState(false)
  const [blogChecked, setBlogChecked] = useState(false)


  const [gclid, setGclid] = useState(null);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const gclidParam = params.get('gclid');
    if (gclidParam) {
      setGclid(gclidParam);
    }
  }, []);

  //Funcion envio formulario
  const handleSubmit = async () => {
    const isValid = validateAll()

    //Validar los campos
    if (isValid) {
      setSpin(true)
      setTimeout(() => {
        setSpin(false)
        onClickConfirm(true)
      }, 5000)
      try {
        const params = new URLSearchParams()
        params.append("landing", "Hablemos") // Esto debe ser "Hablemos"
        params.append("firstname", dataOne.name)
        params.append("lastname", dataOne.lastname)
        params.append("email", dataOne.email)
        params.append("company", dataOne.company)
        params.append("jobtitle", dataOne.jobtitle)
        params.append("website", dataOne.website)
        params.append("codeCountry", dataOne.codeCountry.code)
        params.append("phone", dataOne.phone)
        params.append("channels", dataTwo.channels)
        params.append("description", dataTwo.description)
        params.append("workers", dataTwo.workers) // Valores permitidos: '1-4','5-10','11-20','21-30','40 a más'
        params.append("redes", products)
        params.append("blogChecked", blogChecked)
        params.append("gclid", gclid)

        await fetch(`/backend/lets-talk.php`, {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Accept: "*/*",
          },
          body: params,
        })
      } catch (error) {
        console.log(error)
      }
    } else {
      return false
    }
  }

  return (
    <div className={"container-page-hablemos-form"}>
     <hr className="container-page-hablemos-form-blueTotal" />
      <div className="container-page-hablemos-form-form">
        <div className="container-page-hablemos-form-title">
          <p className="container-page-hablemos-form-title_title">
            ¿Cómo nos descubriste?
          </p>
          <div className="container-page-hablemos-form-title_view" >
          Paso 3/3
          </div>
        </div>
        <div className="container-page-hablemos-form-container_radio">
          <p className="container-page-hablemos-form-inputContainer_label">
            ¿Por cuál medio o canal llegaste a Beex?
          </p>
          <div className="container-page-hablemos-form-container_radio-products">
            {optionsRedes.map(option => (
              <div
                key={option.id}
                className={`container-page-hablemos-form-container_radio-products-card ${selectedOptionProduct === option.id ? "selected" : ""}`
                }
              >
                <input
                  type="radio"
                  id={`product${option.id}`}
                  name="products"
                  value={products}
                  checked={selectedOptionProduct === option.id}
                  onChange={() => handleOptionProductChange(option)}
                />

                <label htmlFor={`product${option.id}`}>
                  <div className="container-page-hablemos-form-container_radio-products-card-info">
                    <img style={{ boxShadow: 'none' }} src={option.image} alt={option.title} />
                    <div>
                      <p>
                        <strong>{option.title}</strong>{" "}
                      </p>
                      <p>{option.description}</p>
                    </div>
                  </div>
                </label>
              </div>
            ))}
          </div>
          <br />
          <div className="error">{productsVal}</div>
        </div>
        <div className="container-page-hablemos-form-checkox-demo">
          <input
            type="checkbox"
            value={blogChecked}
            onChange={() => setBlogChecked(!blogChecked)}
            defaultChecked={blogChecked}
          />
          <div>
            <p className="container-page-hablemos-form-checkox-demo-strong">
              Deseo recibir artículos del blog de Beex 😍
            </p>
            <p className="container-page-hablemos-form-checkox-demo-description">
              Prometemos solo enviarte información relevante. Nada de spam ni
              información innecesaria.{" "}
            </p>
          </div>
        </div>
        <div
          style={{
            marginTop: "31px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <button
            className="container-page-hablemos-form-btnSecondary"
            onClick={() => {
              setSectionViewTwo(true)
              document.body.scrollTop = 0 // For Safari
              document.documentElement.scrollTop = 0 // For Chrome, Firefox, IE and Opera
            }}
          >
            <svg
              width="25"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.0303 18.5303C11.7374 18.8232 11.2626 18.8232 10.9697 18.5303L4.96967 12.5303C4.67678 12.2374 4.67678 11.7626 4.96967 11.4697L10.9697 5.46967C11.2626 5.17678 11.7374 5.17678 12.0303 5.46967C12.3232 5.76256 12.3232 6.23744 12.0303 6.53033L7.31066 11.25L19.5 11.25C19.9142 11.25 20.25 11.5858 20.25 12C20.25 12.4142 19.9142 12.75 19.5 12.75L7.31066 12.75L12.0303 17.4697C12.3232 17.7626 12.3232 18.2374 12.0303 18.5303Z"
                fill="#262A31"
              />
            </svg>{" "}
            Volver
          </button>
          <button
            className="container-page-hablemos-form-btnPrimary"
            onClick={e => handleSubmit(e)}
          >
            Continuar{" "}
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.4697 5.46967C12.7626 5.17678 13.2374 5.17678 13.5303 5.46967L19.5303 11.4697C19.8232 11.7626 19.8232 12.2374 19.5303 12.5303L13.5303 18.5303C13.2374 18.8232 12.7626 18.8232 12.4697 18.5303C12.1768 18.2374 12.1768 17.7626 12.4697 17.4697L17.1893 12.75H5C4.58579 12.75 4.25 12.4142 4.25 12C4.25 11.5858 4.58579 11.25 5 11.25H17.1893L12.4697 6.53033C12.1768 6.23744 12.1768 5.76256 12.4697 5.46967Z"
                fill="#262A31"
              />
            </svg>
            {spin ? (
              <Loader type="Oval" color="#5e5e5e" height={20} width={20} />
            ) : (
              ""
            )}
          </button>
        </div>
      </div>
    </div>
  )
}

export default ViewThree